import * as React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

class ApproachPage extends React.Component {
  componentDidMount() {
    var dropdowns = document.querySelectorAll('.dropdown');
    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].addEventListener('click', function(e){
        var dropdown = e.target.closest('.dropdown');
        var height = dropdown.querySelector('.inner').offsetHeight;
        if(dropdown.classList.contains('active')) {
          dropdown.classList.remove('active');
          dropdown.querySelector('.content').style.height = '0px';
        } else {
          dropdown.classList.add('active');
          dropdown.querySelector('.content').style.height = height+'px';
        }
      });
    }
  }
  componentDidUpdate() {
    window.scrollTo(0,0);
  }
  render() {
    const {data} = this.props
    return (
      <Layout>
        <Seo title="Our Approach"/>
        <div className="hero-banner h-100 mh-50 bg-yellow flex m-wrap">
          <div className="w-50 m-100 flex align-center m-hide">
            <div className="title-area p20 max-750 pos-rel z-2">
              <h1 className="m0 fade-right" data-sal data-sal-delay="500">{data.allDatoCmsApproach.edges[0].node.bannerTitleOne}</h1>
              <h1 className="m0 fade-right" data-sal data-sal-delay="800">{data.allDatoCmsApproach.edges[0].node.bannerTitleTwo}</h1>
            </div>
          </div>
          <div className="w-50 m-100 pos-rel">
            <GatsbyImage image={data.allDatoCmsApproach.edges[0].node.bannerImage.gatsbyImageData} alt="About GM" className="bg-image" />
          </div>
        </div>
        <div className="title-area m-yellow">
          <div className="title-area m-show p20 max-750 pos-rel z-2">
            <h1 className="m0 fade-right" data-sal data-sal-delay="500">{data.allDatoCmsApproach.edges[0].node.bannerTitleOne}</h1>
            <h1 className="m0 fade-right" data-sal data-sal-delay="800">{data.allDatoCmsApproach.edges[0].node.bannerTitleTwo}</h1>
          </div>
          <div className="spacer m-show"></div>
          <div className="approach-introduction p20 mt20 h2">
            <div className="max-1000 fade-in" data-sal data-sal-delay="300">
              {data.allDatoCmsApproach.edges[0].node.introductionContent}
            </div>
          </div>
        </div>
        <div className="spacer"></div>

        <div className="need-section p20">
          <p className="h3 m0 mb20 fade-right" data-sal data-sal-delay="300">Identify your need</p>
          <div className="max-750 op-50 fade-in" data-sal data-sal-delay="500">
           {data.allDatoCmsApproach.edges[0].node.identifyYourNeed}
          </div>
        </div>

        <div className="spacer half"></div>

        <div className="tailor-section p20">
          <p className="h3 m0 mb20 fade-right" data-sal data-sal-delay="400">Tailor a solution</p>
          <div className="max-750 op-50 fade-in" data-sal data-sal-delay="500">
            {data.allDatoCmsApproach.edges[0].node.tailorASolution}
          </div>
        </div>

        <div className="spacer half"></div>

        <div className="dropdown-content p20 fade-in" data-sal data-sal-delay="500">
          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle1}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent1 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle2}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent2 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle3}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent3 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle4}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent4 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle5}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent5 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle6}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent6 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle7}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent7 }} />
            </div>
          </div>

          <div className="dropdown">
            <div className="title">
              {data.allDatoCmsApproach.edges[0].node.dropdownTitle8}
            </div>
            <div className="content op-50">
              <div className="inner pb20"dangerouslySetInnerHTML={{ __html: data.allDatoCmsApproach.edges[0].node.dropdownContent8 }} />
            </div>
          </div>

        </div>

        <div className="spacer half"></div>

        <div className="tailor-section p20">
          <p className="h3 m0 mb20 fade-right" data-sal data-sal-delay="300">{data.allDatoCmsApproach.edges[0].node.monitorTitle}</p>
          <div className="max-750 op-50 fade-in" data-sal data-sal-delay="500">
            {data.allDatoCmsApproach.edges[0].node.monitorContent}
          </div>
        </div>
        <div className="spacer m-hide"></div>

        <div className="spacer half m-show"></div>

        <div className="cta-section bg-yellow p20">
          <div className="spacer half m-show"></div>
          <div className="title-area">
            <h1 className="m0 mt20">
              <span className="fade-right inline" data-sal data-sal-delay="300">{data.allDatoCmsApproach.edges[0].node.ctaTitleOne}</span>
              <br/><span className="fade-right inline" data-sal-delay="500" data-sal>{data.allDatoCmsApproach.edges[0].node.ctaTitleTwo}</span>
            </h1>
          </div>
          <div className="spacer"></div> 
          <div className="mb20 fade-in" data-sal data-sal-delay="500">
            <Link to="/contact" className="link with-arrow h3">Contact Us</Link> 
          </div>
        </div>
      </Layout>
    )
  }
}

export const approachQuery = graphql`
  query approachQuery {
    allDatoCmsApproach {
      edges {
        node {
          bannerImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
          bannerTitleOne
          bannerTitleTwo
          dropdownContent1
          dropdownContent2
          dropdownContent3
          dropdownContent4
          dropdownContent5
          dropdownContent6
          dropdownContent7
          dropdownContent8
          dropdownTitle1
          dropdownTitle2
          dropdownTitle3
          dropdownTitle4
          dropdownTitle5
          dropdownTitle6
          dropdownTitle7
          dropdownTitle8
          identifyYourNeed
          introductionContent
          monitorContent
          monitorTitle
          tailorASolution
          ctaTitleOne
          ctaTitleTwo
        }
      }
    }
  }
`

export default ApproachPage
